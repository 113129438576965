/* Global */
a {
    color: inherit;
}

a:hover {
    color: inherit;
    opacity: 0.8;
    text-decoration: none;
}

a:active {
    opacity: 0.6;
}

.add-story {
    right: 50px;
    top: 15px;
}

.sticky-position {
    position: sticky;
    top: 15px;
    z-index: 1;
}

.color-cobalt {
    color: #637590;
}

.read-smore__link {
    border-bottom: 1px solid;
}

.nav-item.note::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #1bba8c;
    position: absolute;
    border-radius: 2px;
    right: 4px;
    top: 4px;
}

.block-legend {
    background: #fff8f6;
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    color: #f78a8a;
    border: 1px dashed;
    width: 100%;
}

.max-w-dynamic {
    width: 100% !important;
}

.w-xl-100 {
    width: initial;
}

a.from-href {
    font-weight: 700;
}

.w-dynamic {
    width: auto;
    display: inline-block;
}

.card-heading {
    max-width: calc(100% - 55px);
    display: inline-block;
}

.edit-card {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 40px;
    height: 35px;
}

.feather {
    pointer-events: none;
}

.nav-link .feather {
    margin-top: -3px;
}

td.title {
    font-size: 15px;
}

.terms {
    font-size: 12px;
}

.terms.over {
    color:#c1286d;
}

.item-body span.number {
    font-size: 12px;
}

span.number {
    font-size: 13px;
    padding: 2px 3px;
    border: 1px solid rgb(202 202 202);
    border-radius: 4px;
    pointer-events: none;
    cursor: default;
}

span.noname {
    color: #a8a8a8;
    font-weight: 100;
}

/*
.table tr:last-child,
.table tr:last-child > td {
    border-color: transparent !important;
}
*/

.worklist .w-dynamic {
    width: 100%;
}

.nice-select2 {
    border: 1px solid #ced4da;
}

.air-datepicker-global-container {
    z-index: 101;
}

/* chat */
.chat-messages {
    display: flex;
    flex-direction: column;
}

.chat-message-item {
    min-width: 230px;
    max-width: 320px;
    position: relative;
}

.chat-message-item::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0px;
    z-index: -1;
}

.chat-message-right .chat-message-item::after {
    border-bottom: 0px solid transparent;
    border-left: 40px solid #e7ecf3;
    border-top: 40px solid transparent;
    right: -15px;
}

.chat-message-left .chat-message-item::after {
    border-bottom: 0px solid transparent;
    border-right: 40px solid #ebf7ef;
    border-top: 40px solid transparent;
    left: -15px;
}

.chat-message-left,.chat-message-right {
    display: flex;
    flex-shrink: 0
}

.chat-message-left {
    margin-right: auto;
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto
}

.chat-message-right .chat-message-item {
    background-color: #e7ecf3 !important;
    margin-right: 20px;
}

.chat-message-left .chat-message-item {
    background-color: #ebf7ef !important;
    margin-left: 20px;
}
/* */

/* placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--bs-gray-400) !important;
    font-weight: 100 !important;
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--bs-gray-400) !important;
    font-weight: 100 !important;
}
::-ms-input-placeholder { /* IE 10+ */
    color: var(--bs-gray-400) !important;
    font-weight: 100 !important;
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--bs-gray-400) !important;
    font-weight: 100 !important;
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--bs-gray-400) !important;
    font-weight: 100 !important;
}

/* badge bordered */
.badge-bordered {
    background: none !important;
    border: 1px solid !important;
    font-weight: 700;
}
.bg-dark.badge-bordered {
    border-color: rgba(var(--bs-dark-rgb), 0.4) !important;
    color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-dark-rgb), 0.05) !important;
}
.bg-success.badge-bordered {
    border-color: rgba(var(--bs-success-rgb), 0.4) !important;
    color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-success-rgb), 0.05) !important;
}
.bg-info.badge-bordered {
    border-color: rgba(var(--bs-info-rgb), 0.4) !important;
    color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-info-rgb), 0.05) !important;
}
.bg-danger.badge-bordered {
    border-color: rgba(var(--bs-danger-rgb), 0.4) !important;
    color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-danger-rgb), 0.05) !important;
}
.bg-warning.badge-bordered {
    border-color: rgba(var(--bs-warning-rgb), 0.4) !important;
    color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-warning-rgb), 0.05) !important;
}
.bg-light.badge-bordered {
    border-color: rgba(var(--bs-light-rgb), 0.4) !important;
    color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-light-rgb), 0.05) !important;
}
.bg-primary.badge-bordered {
    border-color: rgba(var(--bs-primary-rgb), 0.4) !important;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-primary-rgb), 0.05) !important;
}
.bg-secondary.badge-bordered {
    border-color: rgba(var(--bs-secondary-rgb), 0.4) !important;
    color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-secondary-rgb), 0.05) !important;
}
.bg-indigo.badge-bordered {
    color: #9868ff;
    background-color: #f5eeff !important;
    border-color: #bc85ffa8 !important;
}
.bg-magenta.badge-bordered {
    color: #e83cc5;
    background-color: #ffeefbad !important;
    border-color: #ff81e4 !important;
}
/* MODERN BROWSER */
::placeholder {
    color: var(--bs-gray-400) !important;
}

.card.no-border {
    border-color: transparent;
    box-shadow: none !important;
}

.form-control.is-invalid, .form-select.is-invalid, .choices.is-invalid {
    border-color: #dc3545 !important;
}

@media (min-width:768px) {
    .main {
        overflow: unset !important;
    }

    .max-w-dynamic {
        width: 60% !important;
    }

    .w-xl-100 {
        width: 100%;
    }

    .to-do {
        top: 15px !important;
        left: 10px;
        right: auto !important;
    }

    .add-story {
        right: 150px;
        top: 8px;
    }
}

@media (max-width:767px) {
    .main {
        overflow: hidden !important;
    }

    main.content {
        padding: 1.5rem 0px !important;
    }

    .card {
        box-shadow: none;
        border: 1px solid #b4b4b4;
        margin: 3vw;
    }

    .form-control, .form-select {
        min-height: calc(2.1875rem + 12px);
        border: 1px solid #9c9abd !important;
    }

    

    .w-dynamic {
        width: 100%;
    }
    
    .nice-select2 {
        min-height: calc(2.1875rem + 12px);
        border: 1px solid #9c9abd !important;
    }

    .nice-select span.current {
        margin-top: 4px;
        display: inherit;

        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .eins-modal-content {
        max-width: 370px;
    }

    .eins-modal {
        left: calc(50% - (370px / 2)) !important;
    }
}

.select-lr {
    border-radius: 5px 0px 0px 5px !important;
}

.nice-select-dropdown {
    width: 100%;
    border: 1px solid #b2c2d7d4;
    box-shadow: none !important;
}

.nice-select .list {
    margin-bottom: 0px !important;
}

.nice-select .list li + li {
    border-top: 1px solid #dee2f3c2;
}

.nice-select.open .nice-select-dropdown {
    transform: scale(1.02) translateY(0) !important;
}

.list-group-item .options {
    position: absolute;
    top: 8px;
    right: 15px;
 }

.wrapper {
    background: transparent;
}

.navbar {
    box-shadow: none !important;
}

.logo {
    width: 200px;
    height: 40px;
}

.logo img {
    width: 190px;
    position: absolute;
    top: 5px;
}

.nav-icon {
    color: #343443;
}


.card-form {
    background: #fff;
    border: 1px solid #b2c2d7d4;
}

.main {
    background: #fff;
}

.my-button {
    box-shadow: 0px 2px 0px 0px;
    font-size: 16px;
}

.to-do {
    top: 0px;
    right: 10px;
}

.my-button.btn-primary {
    background: #fff !important;
    color: #30406c!important;
    border-color: #c8c9ff !important;
}

.my-button.btn-success {
    background: #f3fff4 !important;
    color: #306c56!important;
    border-color: #9cc3bd !important;
}

.my-button-sm {
    font-size: 14px;
}

.my-hidden-button {
    border: 1px solid #d1d9f4;
    padding: 5px 10px;
    border-radius: 4px;
    color: #2f416c;
    box-shadow: 0px 1px 0px 0px #8e8bb8;
}

.my-button.secondary {
    border: none;
    box-shadow: none;
}

.container-header {
    padding: 0px 20px;
}

.card-title {
    color: var(--bs-card-color);
}

.i-small {
    width: 10px;
}

.table {
    margin: 0px;
}

h1.h3 {
    color: #495057;
}

.card.current {
    /*border: 2px solid #fff;
    box-shadow: 0px 0px 3px 1px rgb(116 116 116 / 88%);
    */
    border: 1px solid #202046;
}

.card-header {
    padding: 15px 13px;
}

.with-border {
    border: 1px solid #b2c2d7d4;
    border-radius: var(--bs-card-border-radius);
}

a.dropdown-item {
    font-size: 15px;
}

li.nav-item > a {
    color: #343434 !important;
    border: 1px solid #fff;
}

li.nav-item > a.active {
    background-color: #ffffff !important;
    border: 1px solid #c5c5c5;
    color: #343434 !important;
    font-weight: 700;
}

select[readonly]{
    background: #eee;
    pointer-events: none;
}

tr.current td,
.list-group-item.current {
    background: rgb(183 195 204 / 20%);
}

.story-item td {
    padding-top: 8px;
    padding-bottom: 8px;
}

.work-legend {
    background: #becbdd8c;
    display: inline-block;
    width: 100%;
}

.performer-legend {
    background: #fdf1d3 !important;
    color: #b77a59;
}

.card-actions a {
    color: var(--bs-gray-dark);
}

.badge.bg-my {
    /*color: #495665;*/
    color: inherit;
    font-size: 13px;
    font-weight: 100;
    /*border: 1px solid rgb(52 62 77 / 28%);*/
}

.bg-my .feather.feather-clock {
    width: 17px;
    stroke-width: 1;
    margin-top: -1px;
}

.is-new {
    border: 1px solid #ff00592e;
    border-radius: 3px;
    color: #ff004fa1;
    font-size: 12px;
    padding: 0 4px;
    position: absolute;
    right: 6px;
    top: -4px;
    background: #fff0f0e0;
    font-weight: 500;
}

.badge-count {
    padding: 1px 4px;
    background: #ff7f29;
    font-size: 11px;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    pointer-events: none;
}

/* loader */
.wrapper-events-none {
    pointer-events: none;
}

.loading-bg {
    z-index: 110;
    background: rgb(165 183 234 / 31%);
}

.loading {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
    padding: 14px;
    background: #fff;
    box-shadow: 0px 6px 8px 2px #34343426;
    border-radius: 10px;
}

/* sidebar */
.sidebar {
    background: #ffffff42;
    /* box-shadow: inset -3px 0 5px 0 #bcc9d3; */
    transition: none!important;
    border-right: 1px solid #bfccde;
}

.sidebar-content {
    background: none!important;
    position: sticky;
    top: 30px;
    height: auto;
    /*border: 1px solid #b2c2d7d4;*/
    /*box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);*/
    padding: 30px 0px 30px 0px;
    border-radius: 10px 10px 10px 10px;
    margin-right: 0px;
    margin-left: 10px;
    transition: inherit;
}

.sidebar-brand {
    color: #495057;
    font-weight: 200;
}

.sidebar-brand:hover {
    text-decoration: none;
    color: inherit;
  }

a.sidebar-link {
    background: none !important;
    font-size: 15px;
}

.sidebar-item.active > a.sidebar-link {
    border-left-color: transparent !important;
    /*background: #b2c2d7d4 !important;*/
    font-size: 17px;
    font-weight: 800;
}

.sidebar-user {
    padding: 0.75rem 1.5rem 1.75rem 1.5rem;
}

.sidebar-user a {
    outline: 0;
    text-decoration: none;
}

.sidebar-user-title, .sidebar-user-title:hover {
    color: #355b91;
    font-weight: 700;
}

.sidebar-user-subtitle {
    color: #355b91;
}

.sidebar-link,
a.sidebar-link {
    color: rgba(4, 65, 125, 0.82);
}

.sidebar-link i, .sidebar-link svg, a.sidebar-link i, a.sidebar-link svg {
    color: rgba(4, 65, 125, 0.82);
}

.sidebar-link:hover {
    color: rgba(41, 106, 171, 0.82);
    background: #222E3C;
    border-left-color: transparent;
  }
  .sidebar-link:hover i,
  .sidebar-link:hover svg {
    color: rgba(41, 106, 171, 0.82);
  }
  
  .sidebar-item.active > .sidebar-link,
  .sidebar-item.active .sidebar-link:hover {
    color: rgba(4, 65, 125, 0.82);
  }
  .sidebar-item.active > .sidebar-link i,
  .sidebar-item.active > .sidebar-link svg,
  .sidebar-item.active .sidebar-link:hover i,
  .sidebar-item.active .sidebar-link:hover svg {
    color: rgba(4, 65, 125, 0.82);
  }

.sidebar-header {
    color: rgba(41, 106, 171, 0.82);
}

li.sidebar-item+.sidebar-item:before {
    border-bottom: 1px solid #d8e2f0;
    width: calc(100% - 20px);
    margin-left: 10px;
    display: inherit;
    content: "";
}

/* definition-item */
.definition-description-block {
    display: inline-block;
}

.definition-solution-block {
    display: inline-block;
}

.definition-description-block + .definition-solution-block {
    margin-top: 0px !important;
}


.entity-ident {
    height: 6px;
    position: absolute;
    width: 30%;
    top: 0px;
    right: 5%;
    border-radius: 0px 0px 5px 5px;
}

.nav-item {
    position: relative;
}

a.nav-link {
    padding-bottom: 15px;
}

.entity-ident-tab {
    background-color: #fe8961;
    border-radius: 4px 4px 4px 4px;
    height: 3px;
    left: 13%;
    position: absolute;
    bottom: 7px;
    width: 74%;
}

.table-mobile-responsive tr:last-child td {
    border-bottom: 0px;
}

.performers-icon .feather {
    width: 14px;
}

.item-breadcrumbs {
    border-bottom: 0px;
    box-shadow: none;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 10px;
    padding: 10px 15px 5px 15px;
}

.bw-2 {
    border-width: 2px;
}

.bg-color-definition {
    background-color: #86a5ea; 
}
.border-color-definition {
    border-color: #86a5ea;
}

.bg-color-story {
    background-color: #ea87a5;
}
.border-color-story {
    border-color: #ea87a5;
}

.bg-color-task {
    background-color: #ffa34a;
}
.border-color-task {
    border-color: #ffa34a;
}


/* timeline */
.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 24px;
}

.timeline-item {
    position: relative;
    padding: 10px 0px 0px 0px;
}

.timeline-item:before, 
.timeline:before {
    content: " ";
    display: inline-block;
    position: absolute;
    z-index: 0;
}

.timeline:before {
    background: #dee2e6;
    height: 100%;
    left: 4px;
    width: 1px;
}

.timeline-item:before {
    background: #fff;
    border: 2px solid #acc0dc;
    border-radius: 50%;
    height: 10px;
    left: -24px;
    top: 16px;
    width: 10px;
}

.timeline-item + .timeline-item {
    border-top: 1px solid var(--bs-border-color);
}

.progress {
    --bs-progress-bg: #d3dce6;
}

.progress-sm {
    height: 0.4rem;
}

:root {
    --tmr-bootstrap-border-color: #dee2e6;
    --tmr-white: #eee;
    --tmr-table-header: #54667a;
    --tmr-row-divider-color: var(--bs-border-color);
    --tmr-stripped-row-background-color: rgba(0, 0, 0, 0.05);
}

/*-- ==============================================================
 Screen smaller than 760px and iPads.
 ============================================================== */

 @media only screen and (max-width: 760px),
 (min-device-width: 768px) and (max-device-width: 1024px) {
     
     [data-content]:before {
         content: attr(data-content);
     }
     
     /* Force table to not be like tables anymore */
     .table-mobile-responsive,
     .table-mobile-responsive thead,
     .table-mobile-responsive tbody,
     .table-mobile-responsive th,
     .table-mobile-responsive td,
     .table-mobile-responsive tr {
         display: block;
         position: relative;
     }
 
     .table-mobile-responsive.text-center {
         text-align: left !important;
     }
     .table-mobile-responsive caption {
         width: max-content;
     }
 
     /* Hide table headers (but not display: none;, for accessibility) */
     .table-mobile-responsive thead tr {
         position: absolute;
         top: -9999px;
         left: -9999px;
     }
 
     .table-mobile-responsive> :not(:first-child) {
         border-top: none;
     }
 
     .table-mobile-responsive>:not(caption)>*>* {
         border-color: var(--tmr-bootstrap-border-color);
     }
 
     .table-mobile-responsive tr:not(.bg-light-blue) {
         border-bottom: 1px solid var(--tmr-row-divider-color);
     }

    .table-mobile-responsive tr {
        padding-top: 15px;
        padding-bottom: 10px;
    }
 
     /* Default layout */
     .table-mobile-responsive td {
         /* Behave  like a "row" */
         border: none;
         position: relative;
         padding-top: 0rem !important;
         width: 90%;
         text-align: left !important;
     }

     .table-mobile-responsive tr > td:first-child {
        font-weight: inherit;
     }

     .table-mobile-responsive td.options {
        position: absolute;
        top: 10px;
        right: 0px;
        width: 45px;
     }
 
     .table-mobile-responsive td:before {
         /* Now like a table header */
         position: absolute;
         /* Top/left values mimic padding */
         top: 0;
         width: 45%;
         padding-right: 10px;
         white-space: nowrap;
         font-weight: bold;
         color: var(--tmr-table-header);
     }
     
     /* Sided layout */
     .table-mobile-responsive.table-mobile-sided> :not(:first-child) {
         border-top: none;
     }
 
     .table-mobile-responsive.table-mobile-sided>:not(caption)>*>* {
         border-color: var(--bs-table-border-color);
     }
     .table-mobile-responsive.table-mobile-sided td {
           /* Behave  like a "row" */
         border: none;
         border-bottom: 1px solid var(--tmr-white);
         position: relative;
         padding-left: 0%;
         padding-top: 0px !important;
         display: flex;
         justify-content: flex-end;
     }
     .table-mobile-responsive.table-mobile-sided td:before {
         /* Now like a table header */
         position: absolute;
         /* Top/left values mimic padding */
         top: 0;
         left: 0px;
         width: 45%;
         padding-right: 10px;
         white-space: nowrap;
         font-weight: bold;
         color: var(--tmr-table-header);
     }
 
     /* Styleless */
     .table-mobile-responsive.table-mobile-styleless tr:not(.bg-light-blue) {
         border-bottom: none !important;
     }
 
     /* Stripped rows */
     .table-mobile-responsive.table-mobile-striped>tbody>tr:nth-of-type(odd)>* {
         background-color: var(--tmr-stripped-row-background-color) !important;
     }
 }

.slider-wrapper {
    border: 1px dashed #c7c7c7;
    border-radius: 8px;
}

.noUi-connect {
    background: linear-gradient(to right, rgb(169 60 190 / 57%), rgb(141 95 232));
}

.image {
    box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, 0.05);
    border-radius: 4px;
    border: 1px solid #becbdd;
    text-align: center;
}

.image img {
    border-radius: 3px;
    padding: 1px 0px;
}

.image span {
    font-size: 9px;
    height: 15px;
    display: inline-block;
    padding: 0px 5px;
}

.story-item-definition-user {
    background: #fdf1d3;
    color: #b77a59;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
}

.story-item-definition-user .feather {
    width: 14px;
    height: 14px;
}

.story-item-name {
    width: 50%;
}

@media (min-width: 1280px) {
    .max-width-60 {
        max-width: 60%;
    }
}